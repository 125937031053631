import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/search/:type/:id',
    name: 'ArticleSearch',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/article/detail/:id',
    name: 'ArticleDetail',
    component: () => import('@/views/article.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about.vue')
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('@/views/disclaimer.vue')
  },

  {
    path: '/tools',
    name: 'Tools',
    component: () => import('@/views/tools')
  },
  {
    path: '/tools/json',
    name: 'ToolsJson',
    component: () => import('@/views/tools/json.vue')
  },
  {
    path: '/tools/jingcai',
    name: 'ToolsJingcai',
    component: () => import('@/views/tools/jingcai/Index.vue')
  }
]

// add route path
routes.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
})

const router = createRouter({
  history: createWebHashHistory(), //hash模式
  routes: routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  }
})

export default router
