const keepAliveModule = {
  namespaced: true,
  state: {
    keepAliveList: []
  },
  mutations: {
    getKeepAlive(state, data) {
      const find = state.keepAliveList.find((item) => item === data)
      if (!find) state.keepAliveList.push(data)
    },
    removeKeepAlive(state, data) {
      const index = state.keepAliveList.indexOf(data)
      if (index > -1) state.keepAliveList.splice(index, 1)
    }
  },
  actions: {
    setKeepAlive({ commit }, data) {
      commit('getKeepAlive', data)
    },
    removeKeepAlive({ commit }, data) {
      commit('removeKeepAlive', data)
    }
  }
}

export default keepAliveModule
