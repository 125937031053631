const configModule = {
  namespaced: true,
  state: {
    blogInfo: {},
    types: []
  },
  mutations: {
    getBlogInfo(state, data) {
      state.blogInfo = data
    },
    getTypes(state, data) {
      state.types = data
    }
  },
  actions: {
    setBlogInfo({ commit }, data) {
      commit('getBlogInfo', data)
    },
    setTypes({ commit }, data) {
      commit('getTypes', data)
    }
  }
}

export default configModule
