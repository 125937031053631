import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store'
import ElementPlus from 'element-plus'

import JsonViewer from 'vue3-json-viewer'

import '@/styles/css/index.scss'
import hljs from 'highlight.js'

const app = createApp(App)
app.use(router).use(store).use(JsonViewer).use(ElementPlus).mount('#app')

app.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    // hljs.highlightElement(block)
    // block.innerHTML = `<ol><li class="line">${block.innerHTML.replace(/\n/g, `</li><li class="line">`)}</li></ol>`
    // 1.创建ul节点
    let ul = document.createElement('ul')
    // 2.根据换行符获取行数，根据获取的行数生成行号
    let rowCount = block.outerHTML.split('\n').length
    for (let i = 0; i <= rowCount; i++) {
      //创建li节点，创建文本节点
      let li = document.createElement('li')
      let text = document.createTextNode(i === 0 ? ' ' : i)
      //为li追加文本节点，将li加入ul
      li.appendChild(text)
      ul.appendChild(li)
    }
    // 3.给行号添加类名
    ul.className = 'pre-numbering line-number'
    // 4.将ul节点加到 代码块
    block.parentNode.appendChild(ul)

    hljs.configure({
      // 忽略未经转义的 HTML 字符
      ignoreUnescapedHTML: true
    })
    hljs.highlightElement(block)
  })
})
